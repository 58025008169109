.stack{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px
}

.heading{
  flex: 1;
  margin: 0px;
}